import { osVersion, osName, isMobile } from 'react-device-detect';
import { diffTimeInDaysWithCurrentDay, sendErrToSentry } from 'utils/common';
import * as USER from 'data/user';

import { postRequest, getRequest } from 'utils/requests';

export const checkFraud = (externalID, siteID) => {
    try {
        getRequest(`https://extreme-ip-lookup.com/json`).then((ipData) => {
            if (ipData) {
                postRequest(`/api/v3/fraud/register`, {
                    ...ipData,
                    ...{
                        external_id: externalID,
                        site_id: siteID,
                        getFingerprint: localStorage.getItem('fingerprinthash') || '',
                    },
                });
            }
        });
    } catch (error) {
        sendErrToSentry(error);
    }
};

const getBinomo = (cnv_status, clickid) => {
    const eventIndex = +USER.BINOMO_EVENT?.indexOf(cnv_status) + 1;
    const addStatusTail = USER.BINOMO_EVENT?.includes(cnv_status)
        ? `&cnv_status={cnv_status}&event${eventIndex}=1`
        : `&cnv_status=${cnv_status}`;

    return getRequest(
        `https://binom.love-2-date.com/click.php?cnv_id=${clickid}&payout={payout}${addStatusTail}`,
    );
};

export async function sendBinomoFromStore({
    externalID,
    siteID,
    status,
    clickid,
    utm_aff_hash,
    ghost,
    email,
    tail,
    count = 0,
}) {
    const isTestEmail = email?.includes('test');
    if (isTestEmail) return;
    try {
        if (externalID && siteID && !ghost) {
            postRequest('/api/v3/click-history/events', {
                external_id: externalID,
                event: status,
            });
            await checkFraud(externalID, siteID);

            if (status && clickid) {
                if (utm_aff_hash) {
                    const checkOfferReq = await postRequest('/api/tail/checkAffOffer', {
                        external_id: externalID,
                        utm_aff_hash: utm_aff_hash,
                        device: {
                            device: isMobile ? 'mob' : 'desk',
                            os: `${osName} ${osVersion}`,
                        },
                        status,
                    });
                    if (checkOfferReq?.status) {
                        getBinomo(status, clickid);
                        if (tail?.includes('utm_aff=aff_crix') && +count > 0 && +count < 4) {
                            const params = new URLSearchParams(tail);
                            const crixClickid = params.get('utm_medium');
                            const amount = +`1.0${count}`;

                            getRequest(
                                `https://optimizer.brmediatrk.com/sale?cid=${crixClickid}&amount=${amount}`,
                            );
                        }
                    }
                } else getBinomo(status, clickid);
            }
        }
    } catch (error) {
        sendErrToSentry(error);
    }
}

export function diffBinomoByRegisterDate(userInfo, tail, clickid, utm_aff_hash) {
    if (!userInfo?.external_id) return;
    if (!userInfo?.site_id) return;

    const diffDay = JSON.parse(localStorage.getItem(`rd-${userInfo?.external_id}`)) || '';
    const is2Day = JSON.parse(localStorage.getItem(`rdb-2-${userInfo?.external_id}`)) || null;
    const is6Day = JSON.parse(localStorage.getItem(`rdb-6-${userInfo?.external_id}`)) || null;

    if (diffTimeInDaysWithCurrentDay(diffDay) >= 2 && !is2Day) {
        localStorage.setItem(`rdb-2-${userInfo?.external_id}`, '1');
        if (diffTimeInDaysWithCurrentDay(diffDay) === 2) {
            sendBinomoFromStore({
                externalID: userInfo?.external_id,
                siteID: userInfo?.site_id,
                status: USER.BINOMO_AFTER_REGISTER_2_DAY,
                clickid: clickid,
                utm_aff_hash: utm_aff_hash,
                tail: tail,
                email: userInfo?.email,
                ghost: userInfo?.ghost,
            });
        }
    }

    if (diffTimeInDaysWithCurrentDay(diffDay) >= 6 && !is6Day) {
        localStorage.setItem(`rdb-6-${userInfo?.external_id}`, '1');
        if (diffTimeInDaysWithCurrentDay(diffDay) === 6) {
            sendBinomoFromStore({
                externalID: userInfo?.external_id,
                siteID: userInfo?.site_id,
                status: USER.BINOMO_AFTER_REGISTER_6_DAY,
                clickid: clickid,
                utm_aff_hash: utm_aff_hash,
                tail: tail,
                email: userInfo?.email,
                ghost: userInfo?.ghost,
            });
        }
    }
}
