import { configureStore } from '@reduxjs/toolkit';
import { girlsListReducer } from './girlsList/slice';
import { interfaceReducer } from './interface/slice';
import { authReducer } from './auth/slice';
import { chatReducer } from './chat/slice';
import { lettersReducer } from './letters/slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        girlsList: girlsListReducer,
        interface: interfaceReducer,
        auth: authReducer,
        chat: chatReducer,
        letters: lettersReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
