import moment from 'moment';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';

import { postRequest } from './requests';

import { stickersConfig } from 'img/stickers/stikers';

import { CurrencyEnum } from 'data/config';

export const cutTextforToltip = (text, length) => {
    if (!text) return '';
    if (text.length > length) {
        return `${text.slice(0, length)}...`;
    }
    return text;
};

export const sanitizedSearchItem = (searchItem) => {
    return /\d/.test(searchItem) ? searchItem.replace(/\s/g, '') : searchItem;
};

export function switchLookingFor(looking) {
    switch (looking) {
        case 'Man':
            return 0;
        case 'Woman':
            return 1;
        default:
            return 1;
    }
}

export function getUrlParameter(sParam) {
    try {
        let sPageURL = window?.location?.search?.substring(1),
            sURLVariables = sPageURL?.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables?.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined
                    ? true
                    : decodeURIComponent(sParameterName[1]);
            }
        }
    } catch (e) {
        sendErrToSentry(e);
    }
}

export const parserFilterOptions = (oldFilterOptions) => {
    // парсер для создания опций для селектов вида {item:string, value: string}
    if (oldFilterOptions) {
        const keyFiltersName = Object.keys(oldFilterOptions);
        const new_filter = {};
        keyFiltersName.forEach((key) => {
            const nameOption = key.split('_');
            new_filter[key] = oldFilterOptions[key].map((item) => {
                return {
                    id: item.id,
                    value:
                        nameOption[0] !== 'country'
                            ? nameOption[0] !== 'morechildren'
                                ? item[nameOption[0]]
                                : item[`children`]
                            : item[`${nameOption[0]}_name`],
                    label:
                        nameOption[0] !== 'country'
                            ? nameOption[0] !== 'morechildren'
                                ? item[nameOption[0]]
                                : item[`children`]
                            : item[`${nameOption[0]}_name`],
                };
            });
        });
        return new_filter;
    }
};

export const parserFilterForFetch = (obj, search_multigender, gender, images) => {
    const newObj = {};
    for (let key in obj) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
            newObj[key] = obj[key].id;
        }
    }
    if (!search_multigender) {
        newObj.gender = gender;
    }

    if (images) {
        newObj.images = 1;
    }

    newObj.online = obj.online;
    newObj.id = sanitizedSearchItem(obj.id);
    newObj.hasVideo = obj.hasVideo;

    return newObj;
};

export const findStiker = (nameSticker) => {
    const foundSticker = stickersConfig.find(({ stickers }) =>
        stickers.some(({ name }) => name === nameSticker),
    );
    if (!foundSticker) {
        return null;
    }
    const foundImg = foundSticker.stickers.find(({ name }) => name === nameSticker);
    return foundImg.img;
};

export const ageOptions = () => {
    const values = [];
    for (let i = 18; i <= 70; i++) {
        values.push({ value: i, label: i.toString() });
    }
    return values;
};

export const sortCardsToFreeFirst = (cards = []) => {
    const filteredCards = cards.filter((card) => {
        return card?.content_type === 'image';
    });
    const sortedCards = filteredCards.sort((a, b) => {
        if (a.free > b.free) {
            return -1;
        }
        if (a.free < b.free) {
            return 1;
        }
        return 0;
    });
    return sortedCards;
};

export const formatDateTime = (dateTime) => {
    const date = moment(dateTime);
    const currentDate = moment().startOf('day');
    if (date.isSame(currentDate, 'day')) {
        return date.format('h:mm a');
    } else {
        return date.format('DD/MM');
    }
};

export const formatDateToMMMMDDYY = (date) => {
    return moment(date).format('MMM, D YYYY');
};

export const parserSelectedMediaToMailAttachments = (attachments) => {
    return attachments.map((item) => ({
        id: item.id,
        link: item.link,
        title: item.link,
        message_type:
            item.content_type === 'image'
                ? 'SENT_IMAGE'
                : item.content_type === 'video'
                  ? 'SENT_VIDEO'
                  : 'SENT_AUDIO',
    }));
};

export const getDifferenceInSeconds = (date_created, expired_at) => {
    const dateCreatedObj = new Date(date_created);
    const expiredAtObj = new Date(expired_at);
    const timeDiff = expiredAtObj.getTime() - dateCreatedObj.getTime();
    const sec = Math.floor(timeDiff / 1000) * 1000;
    return sec;
};

export function compareDates(firstDate, secondDate) {
    return new Date(firstDate) >= new Date(secondDate);
}

export function objectToArray(obj) {
    if (typeof obj !== 'object') return null;
    const arr = [];

    for (let key in obj) {
        arr.push({ ...{ id: key }, ...obj[key] });
    }

    return arr;
}

export const updateStatusOnline = (
    param,
    array,
    excludeZeroStatus = false,
    nestedKeyPath = '',
    statusPropertyName,
    externalIdKey = 'external_id',
) => {
    const newData = Array.isArray(array) ? [...(array || [])] : [array];

    if (param && param.externalIDList && param.externalIDList.length > 0) {
        const externalIDs = param.externalIDList;
        const newArray = newData
            .filter(
                (item) =>
                    !(
                        excludeZeroStatus &&
                        param.status === 0 &&
                        (nestedKeyPath
                            ? item[nestedKeyPath]?.[externalIdKey]
                            : item[externalIdKey]) === param.external_id
                    ),
            )
            .map((item) => {
                const current = nestedKeyPath ? item[nestedKeyPath] : item;
                const statusKey =
                    current &&
                    (Object.prototype.hasOwnProperty.call(current, 'online') ||
                        Object.prototype.hasOwnProperty.call(current, 'isOnline'))
                        ? Object.prototype.hasOwnProperty.call(current, 'online')
                            ? 'online'
                            : 'isOnline'
                        : statusPropertyName;

                const newStatus =
                    current && typeof current[statusKey] === 'number'
                        ? Number(param.status)
                        : Boolean(param.status);

                if (
                    externalIDs.includes(
                        nestedKeyPath ? current?.[externalIdKey] : item[externalIdKey],
                    )
                ) {
                    if (nestedKeyPath) {
                        return {
                            ...item,
                            [nestedKeyPath]: {
                                ...current,
                                [statusKey]: newStatus,
                            },
                        };
                    } else {
                        return {
                            ...item,
                            [statusKey]: newStatus,
                        };
                    }
                }
                return item;
            });

        return Array.isArray(array) ? newArray : newArray[0];
    }

    return array;
};

export const renderLastMessage = (type, message, fromYou, girlName, isMessager) => {
    let renderData = '';

    switch (type) {
        case 'SENT_IMAGE':
            renderData = 'sent image ';
            break;
        case 'SENT_VIEW':
            renderData = fromYou ? ' Viewed your profile' : '';
            break;
        case 'SENT_AUDIO':
            renderData = 'sent audio ';
            break;
        case 'SENT_VIDEO':
            renderData = 'sent video ';
            break;
        case 'SENT_WINK':
            renderData = isMessager ? message : 'Hello ✋';
            break;
        case 'SENT_LIKE':
            renderData = fromYou
                ? `${girlName} liked your profile`
                : `❤️ You liked ${girlName} profile`;
            break;
        case 'ASK_VIDEO':
        case 'SENT_TEXT':
            renderData = message;
            break;
        case 'SENT_STICKER':
            renderData = 'sent sticker';
            break;
        case 'SENT_VIRTUAL_GIFT':
            renderData = 'sent a virtual gift';
            break;
        default:
            renderData = '';
            break;
    }
    return renderData;
};

export const findButtonConfig = (config, openFilterFn, location, navigate) => {
    for (const btnConfig of config) {
        const isIncludePatchMatched = Array.isArray(btnConfig.includePatch)
            ? btnConfig.includePatch.some(
                  (patch) =>
                      location.pathname.includes(patch) ||
                      new URLSearchParams(location.search).has(patch),
              )
            : location.pathname.includes(btnConfig.includePatch) ||
              new URLSearchParams(location.search).has(btnConfig.includePatch);

        if (isIncludePatchMatched) {
            const buttonData = {
                onClick:
                    btnConfig.action.toLowerCase() === 'open filter'
                        ? openFilterFn
                        : () => {
                              if (btnConfig.action) {
                                  navigate(btnConfig.action);
                              } else {
                                  if (location.state?.from?.includes('cart')) {
                                      navigate(-2);
                                  } else {
                                      navigate(-1);
                                  }
                              }
                          },
                icon: btnConfig.icon,
                text: btnConfig.text,
            };
            return buttonData;
        }
    }

    return undefined;
};

export const getQueryParam = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
};

export const removeQueryParam = (paramName) => {
    const currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has(paramName)) {
        currentUrl.searchParams.delete(paramName);
        window.history.replaceState({}, '', currentUrl.toString());
    }
};

export const birthdayModalText = (birthdayBonusCredits) => {
    switch (+birthdayBonusCredits) {
        case 100:
            return "We know that as your birthday, this day is very special to you! We would like to sincerely congratulate you and wish you the best in another wonderful year. May life bring you the greatest joy and never-ending happiness. As a special gift, we would very much like to allow you to get closer to your sweetheart today. Please use the 100 credits we've added to your account to have an amazing chat with your significant other!";

        case 20:
            return "Don't think for a second that we forgot that today is the greatest holiday for you! Congratulations and best wishes on your birthday, friend! May it bring a smile to your face and passionate love to your heart! Reach out to your beloved member with the 20 free credits we've gifted to you and share your happiness with her in a chat session right now!";
        default:
            return `You received ${+birthdayBonusCredits} credits!`;
    }
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

export const checkTwoInt = (value) => {
    return +value > 9 ? `${value}` : `0${value}`;
};

export const diffTimeInDaysWithCurrentDay = (diffDay) => {
    try {
        return moment(new Date()).diff(moment(diffDay), 'days');
    } catch (e) {
        return 0;
    }
};

export const actionCookies = (cookieName, action) => {
    if (action === 'del' && cookieName) {
        Cookies.remove(cookieName);
        return null;
    } else {
        if (cookieName) {
            return Cookies.get(cookieName);
        } else {
            return Cookies.get();
        }
    }
};

export function decimalAdjust(type, value, exp) {
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }

    if (value < 0) {
        return -decimalAdjust(type, -value, exp);
    }

    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));

    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

export function convertPrice(price) {
    if (!price) return '';
    return decimalAdjust('floor', +price, -2);
}

export function findAge(year, month, day) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dob = new Date(year, month - 1, day);
    const dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
    let age;

    age = today.getFullYear() - dob.getFullYear();

    if (today < dobnow) {
        age = age - 1;
    }

    return +age;
}

export function generateUTMCDate() {
    const date = new Date();

    const dd = +date.getDate() >= 10 ? +date.getDate() : `0${+date.getDate()}`;
    const mm = +date.getMonth() + 1 >= 10 ? +date.getMonth() + 1 : `0${+date.getMonth() + 1}`;
    const yyyy = +date.getFullYear();

    return `utm_cdate=${dd}${mm}${yyyy}`;
}

export function sendErrToSentry(error) {
    if (Sentry?.captureException) {
        Sentry.captureException(error);
    }
}
export const crashCallback = (message) => {
    const fullMessage = `${window?.location?.href} --> ${message}`;
    postRequest(`/api/v3/user-profile/crash`, { message: fullMessage });
};

export function isInjectedCode(event) {
    const frames = event?.exception?.values?.[0]?.stacktrace?.frames;
    if (!frames?.length) return false;

    const [firstFrame, lastFrame] = [frames[0], frames[frames.length - 1]];

    if (firstFrame?.filename === '<anonymous>') return true;

    const lastFilename = lastFrame?.filename || '';
    const isLocalFile =
        lastFilename === window.location.pathname ||
        (lastFilename.startsWith(window.location.origin) && !lastFilename.includes('/static/'));
    if (isLocalFile) return true;

    const externalScripts = [
        'https://www.googletagmanager.com',
        'https://googleads.',
        'https://sgtm.',
    ];

    if (frames.some((frame) => externalScripts.some((url) => frame?.filename?.startsWith(url))))
        return true;

    const errorMessage = event?.exception?.values?.[0]?.value || '';

    return errorMessage.includes(
        "null is not an object (evaluating '/utm_pod=([^&]*)/g.exec(document.URL)[1]')",
    );
}
export const sessionStorageGetItem = (key) => {
    return (sessionStorage && sessionStorage.getItem(key)) || '';
};

export function addUrlParameter(paramsName, paramsValue) {
    const url = new URL(window?.location?.href);
    const searchParams = url?.searchParams;

    if (searchParams?.has(paramsName)) {
        searchParams?.delete(paramsName);
    }

    searchParams?.append(paramsName, paramsValue);
    const newUrl = `${url?.origin}${url?.pathname}?${searchParams?.toString()}`;
    window?.history?.replaceState({}, '', newUrl);
}

export function includesParam(allParams, newParam) {
    let paramKey = newParam;

    if (newParam?.includes('=')) {
        paramKey = newParam?.split('=')?.[0] + '=';
    }

    return allParams?.includes(paramKey);
}

export function removeParam(allParams, toRemove) {
    if (allParams?.length === 0) return allParams;

    let queryParams = allParams;
    if (allParams?.startsWith('?')) queryParams = allParams?.substring(1);

    const params = queryParams?.split('&');
    const attributeName = toRemove?.split('=')?.[0] + '=';

    return params
        ?.filter((p) => {
            return !p?.startsWith(attributeName);
        })
        ?.join('&');
}

export function injectParam(allParams, newParam, index) {
    if (allParams?.length === 0) return newParam;

    let queryParams = allParams;
    if (allParams?.startsWith('?')) queryParams = allParams?.substring(1);

    if (includesParam(queryParams, newParam)) {
        queryParams = removeParam(queryParams, newParam);
    }

    const params = queryParams?.split('&');
    if (params?.length > index) {
        params?.splice(index, 0, newParam);
    } else {
        params?.push(newParam);
    }

    return params?.join('&');
}

export const currencyPriceString = (currency, price) => {
    if (!currency) return `$${convertPrice(price)}`;
    if (currency === CurrencyEnum.GBP) return `£${convertPrice(price)}`;
    if (currency === CurrencyEnum.EUR) return `€${convertPrice(price)}`;

    return `$${convertPrice(price)}`;
};
