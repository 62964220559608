// Filters for Like Page
export const ALL_GIRLS_ONLINE = 'All girls online';
export const WHO_LIKED_YOU = 'Who liked you';
export const WHO_LOOKED_YOU = 'Who viewed your profile';
export const RESET_FILTER = 'Clear all filters';
export const WIDE_FILTER = 'Wide';

export const GEO_AFRICA = [
    'DZ',
    'AO',
    'BJ',
    'BO',
    'BW',
    'BF',
    'BI',
    'CM',
    'CV',
    'CF',
    'TD',
    'KM',
    'CG',
    'CD',
    'CI',
    'DJ',
    'TP',
    'GQ',
    'ER',
    'ET',
    'FJ',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'KE',
    'LS',
    'LR',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'YT',
    'FM',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'PG',
    'RE',
    'RW',
    'ST',
    'SN',
    'SL',
    'SB',
    'SO',
    'ZA',
    'SD',
    'SZ',
    'TZ',
    'TG',
    'TO',
    'UG',
    'VU',
    'EH',
    'ZM',
    'ZW',
    'SZ',
    'SS',
    'TZ',
    'CI',
    'CD',
    'CD',
    'CG',
    'KN',
    'CV',
    'CG',
];
export const GEO_ARABIA = [
    'BH',
    'EG',
    'IR',
    'IQ',
    'IL',
    'JO',
    'KW',
    'LB',
    'LY',
    'OM',
    'PK',
    'PS',
    'QA',
    'SA',
    'SY',
    'TN',
    'TR',
    'AE',
    'YE',
    'IR',
    'LY',
    'SY',
    'JO',
    'PS',
    'PS',
];
export const GEO_ASIA = [
    'AF',
    'AM',
    'AZ',
    'BT',
    'BN',
    'CN',
    'CC',
    'GE',
    'GU',
    'HK',
    'ID',
    'JP',
    'KZ',
    'KP',
    'KR',
    'KG',
    'LA',
    'MO',
    'MY',
    'MN',
    'NP',
    'PH',
    'SG',
    'TW',
    'TJ',
    'TH',
    'TM',
    'UZ',
    'VN',
    'VN',
    'KR',
    'BN',
    'KZ',
    'LA',
    'MO',
    'TL',
    'KH',
    'MM',
];
export const GEO_EURO = [
    'AL',
    'AD',
    'AQ',
    'AU',
    'AT',
    'BY',
    'BE',
    'BA',
    'BG',
    'CA',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FO',
    'FI',
    'FR',
    'TF',
    'DE',
    'GR',
    'GL',
    'VA',
    'HU',
    'IS',
    'IE',
    'IT',
    'LV',
    'LI',
    'LT',
    'LU',
    'MK',
    'MD',
    'MC',
    'NL',
    'NF',
    'NO',
    'PL',
    'RO',
    'RU',
    'PM',
    'SM',
    'SK',
    'SI',
    'GS',
    'SJ',
    'SE',
    'CH',
    'UA',
    'GB',
    'US',
    'UM',
    'YU',
    'LT',
    'XK',
    'CZ',
    'IM',
    'AX',
    'MK',
    'GG',
    'JE',
    'RS',
    'ME',
    'BV',
    'HM',
];
export const GEO_INDIA = ['BD', 'IO', 'CX', 'IN', 'MV', 'PW', 'SC', 'LK'];
export const GEO_LATINA = [
    'AS',
    'AI',
    'AG',
    'AR',
    'AW',
    'BS',
    'BB',
    'BZ',
    'BM',
    'BR',
    'KY',
    'CL',
    'CO',
    'CK',
    'CR',
    'CU',
    'DM',
    'DO',
    'EC',
    'SV',
    'FK',
    'GF',
    'PF',
    'GI',
    'GD',
    'GP',
    'GT',
    'GY',
    'HT',
    'HN',
    'JM',
    'KI',
    'MT',
    'MH',
    'MQ',
    'MX',
    'MS',
    'NR',
    'AN',
    'NC',
    'NZ',
    'NI',
    'NU',
    'MP',
    'PA',
    'PY',
    'PE',
    'PN',
    'PT',
    'PR',
    'SH',
    'KN',
    'LC',
    'VC',
    'WS',
    'ES',
    'SR',
    'TK',
    'TT',
    'TC',
    'TV',
    'UY',
    'VE',
    'VG',
    'VI',
    'WF',
    'FK',
    'SX',
    'VG',
    'VI',
];

export const GEO_TIER1 = [
    'US',
    'GB',
    'CA',
    'FR',
    'DE',
    'JP',
    'KR',
    'AU',
    'NZ',
    'CH',
    'NL',
    'SE',
    'DK',
    'NO',
    'FI',
    'AT',
    'BE',
    'IE',
    'SG',
    'HK',
    'TW',
];
export const GEO_EVENT_PUSH = ['US', 'GB', 'CA', 'AU']; //Google Ads
export const GEO_EVENT_PUSH_V2 = ['US', 'GB', 'CA', 'AU', 'NZ']; //Google Ads

export const BLOCKED_COUNTRIES_EVENT = [
    'AB', // Abkhazia
    'AF', // Afghanistan
    'DZ', // Algeria
    'AO', // Angola
    'BH', // Bahrain
    'BD', // Bangladesh
    'BB', // Barbados
    'BY', // Belarus
    'BW', // Botswana
    'BG', // Bulgaria
    'BF', // Burkina Faso
    'BI', // Burundi
    'CM', // Cameroon
    'CV', // Cape Verde
    'KY', // Cayman Islands
    'CF', // Central African Republic
    'TD', // Chad
    'CN', // China
    'UA-43', // Crimea (region code in Ukraine)
    'CI', // Côte d’Ivoire
    'CU', // Cuba
    'CD', // Democratic Republic of the Congo
    'DJ', // Djibouti
    'UA-14', // Donbas (region code in Ukraine)
    'EG', // Egypt
    'ET', // Ethiopia
    'GM', // Gambia
    'GH', // Ghana
    'GI', // Gibraltar
    'HT', // Haiti
    'HK', // Hong Kong
    'HU', // Hungary
    'IR', // Iran
    'IQ', // Iraq
    'JM', // Jamaica
    'JO', // Jordan
    'KZ', // Kazakhstan
    'KW', // Kuwait
    'LB', // Lebanon
    'LR', // Liberia
    'LY', // Libya
    'UA-09', // Luhansk (region code in Ukraine)
    'ML', // Mali
    'MD', // Moldova
    'MA', // Morocco
    'MZ', // Mozambique
    'MM', // Myanmar
    'NA', // Namibia
    'NP', // Nepal
    'NI', // Nicaragua
    'NE', // Niger
    'NG', // Nigeria
    'KP', // North Korea
    'OM', // Oman
    'PK', // Pakistan
    'PS', // Palestine
    'PA', // Panama
    'PH', // Philippines
    'QA', // Qatar
    'CG', // Republic of the Congo
    'RU', // Russia
    'RW', // Rwanda
    'SA', // Saudi Arabia
    'SN', // Senegal
    'SL', // Sierra Leone
    'SK', // Slovakia
    'SO', // Somalia
    'SS', // South Sudan
    'LK', // Sri Lanka
    'SD', // Sudan
    'SR', // Suriname
    'SY', // Syria
    'TZ', // Tanzania
    'BA', // The Balkans (Bosnia and Herzegovina as a placeholder)
    'TT', // Trinidad and Tobago
    'TN', // Tunisia
    'UG', // Uganda
    'UA', // Ukraine
    'AE', // United Arab Emirates
    'VU', // Vanuatu
    'VE', // Venezuela
    'YE', // Yemen
    'ZM', // Zambia
    'ZW', // Zimbabwe
];
