import React from 'react';
import { useRef, useEffect } from 'react';
import { Textonchat } from 'components/atomsKit/Elements';
import ReactAudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import cn from 'classnames';
import imgPlay from 'img/icons/popup-play-played.svg';
import imgStop from 'img/icons/stop_in_attach_file.svg';
import imgVolume from 'img/icons/audio-volume.svg';
import imgMute from 'img/icons/chat-audio-volume-mute.svg';
import { cutTextforToltip } from 'utils/common';
import classes from './AudioPlayer.module.scss';
import './AudioPlayer.scss';
import PropTypes from 'prop-types';

const AudioPlayer = ({
    id = 0,
    src,
    name = '',
    hideName = false,
    className = '',
    isPlay = true,
    isMessage = false,
    handleContent = (e) => {
        e?.preventDefault();
    },
}) => {
    const audioRef = useRef(null);

    const stopAllPlayers = () => {
        const allPlayer = document.querySelectorAll('.audio-player audio');
        allPlayer.forEach((audio) => {
            if (audio !== audioRef.current.audio.current) {
                audio.pause();
            }
        });
    };

    useEffect(() => {
        stopAllPlayers();
    }, []);

    const handlePlay = () => {
        stopAllPlayers();

        if (isMessage) {
            handleContent(id, isPlay, audioRef.current.audio.current);
        } else {
            if (!isPlay) {
                setTimeout(() => {
                    audioRef.current.audio.current.pause();
                });
                handleContent(() => audioRef.current.audio.current);
            }
        }
    };

    return (
        <div className={cn(classes.wrap, className, 'audio-player')}>
            {name !== '' ||
                (hideName && (
                    <Textonchat text={cutTextforToltip(name, 10)} className={classes.name} />
                ))}
            <ReactAudioPlayer
                autoPlay={false}
                // onPlay={handlePlay}
                ref={audioRef}
                src={src}
                customIcons={{
                    play: (
                        <img
                            onClick={handlePlay}
                            src={imgPlay}
                            alt=""
                            className={cn(name !== '' && classes.playBtn)}
                            loading="lazy"
                        />
                    ),
                    pause: (
                        <img
                            src={imgStop}
                            alt=""
                            className={cn(name !== '' && classes.playBtn)}
                            loading="lazy"
                        />
                    ),
                    volume: <img src={imgVolume} alt="" loading="lazy" />,
                    volumeMute: <img src={imgMute} alt="" loading="lazy" />,
                }}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                showJumpControls={false}
            />
        </div>
    );
};

AudioPlayer.propTypes = {
    id: PropTypes.number,
    src: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.object,
    isPlay: PropTypes.bool,
    isMessage: PropTypes.bool,
    handleContent: PropTypes.func,
    hideName: PropTypes.bool,
};

export default AudioPlayer;
